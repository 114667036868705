




















import { Component, Prop, Vue } from 'vue-property-decorator';
import profile from '@/assets/data/profile';

@Component
export default class Me extends Vue {
  categories: {[key:string]: string}[] = [];

  myAge(): number {
    const ageDifMs = Date.now() - (new Date('1995-11-05')).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  created() {
    this.categories = profile;
  }
}
